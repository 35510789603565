@import "variables";
@import "helpers";
@import "fonts";
@import "styles-legacy";

body {
	background: $color-bg;
	font-size: 12px;
	color: #4c4b53;
	min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
	font-family: $fontalt;
}

.main-container {
	background-color: #fff;
	padding: 30px 30px 0 30px;

	@media (min-width: 991px) {
		padding: 80px 30px 0 30px;

	}
}

.btn-primary,
.btn-default,
a#select-formation,
.cta-link {
	display: inline-block !important;
	margin: 0;
	width: auto !important;
	padding: 15px 15px;

	background-color:                       $color-1 !important;
	.domain-savoie    & { background-color: $color-savoie !important; }
	.domain-vivarais  & { background-color: $color-provence !important; }
	.domain-grenoble  & { background-color: $color-grenoble !important; }
	.domain-arve      & { background-color: $color-arve !important; }
	.domain-nord      & { background-color: $color-nordisere !important; }
	.domain-ardechedrome     & { background-color: $color-ardechedrome !important; }
	.domain-lac       & { background-color: $color-lac !important; }
  .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie !important; }

	border: none !important;
	border-radius: 2px;

	text-align: left;
	color: #fff;
	font-weight: 600;
	text-transform: uppercase;
	white-space: normal;
	text-decoration: none;

	transition: background-color 0.3s ease-in-out;

	&:hover,
	&:focus,
	&:active {
		background-color: $color-top-bar !important;
		color: #fff !important;
	}
}

.cta-link {
	display: block !important;
	margin: 30px 0;
	padding: 20px;
	text-align: center;

	font-size: 20px;
}

.tutu {
	color: $color-1 !important;
	.domain-savoie    & { color: $color-savoie !important; }
	.domain-vivarais  & { color: $color-provence !important; }
	.domain-grenoble  & { color: $color-grenoble !important; }
	.domain-arve      & { color: $color-arve !important; }
	.domain-nord      & { color: $color-nordisere !important; }
	.domain-ardechedrome     & { color: $color-ardechedrome !important; }
	.domain-lac       & { color: $color-lac !important; }
  .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie !important; }
	font-weight: 600 !important;
	padding: 0;
}

/*
##    ##      ###     ##     ##
###   ##     ## ##    ##     ##
####  ##    ##   ##   ##     ##
## ## ##   ##     ##  ##     ##
##  ####   #########   ##   ##
##   ###   ##     ##    ## ##
##    ##   ##     ##     ###
*/

.block-mobile {
	a {
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			background: none !important;
		}
	}
}

.region-top {
	position: relative;
	background-color: $color-top-bar;

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		width: 1200px;
		height: 100%;

		background-color: $color-top-bar;
	}

	&:before { right: 100%; }
	&:after  { left: 100%;  }

	.dropdown-menu {
		margin: 0;

		background-color: darken($color-top-bar, 3%);
		border: none;
		border-radius: 0 !important;


	}

	ul.menu.nav li {
		display: inline;
		float: right;
		padding: 10px 0;
	}

	ul.menu.nav li a {
		display: inline;
		font-size: 11px;
		color: #fff;
		text-transform: uppercase;
		font-weight: normal;
		font-family: $fontalt;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			background-color: transparent;
			color: $color-1;

			.domain-savoie    & { color: $color-savoie; }
			.domain-vivarais  & { color: $color-provence; }
			.domain-grenoble  & { color: $color-grenoble; }
			.domain-arve      & { color: $color-arve; }
			.domain-nord      & { color: $color-nordisere; }
			.domain-ardechedrome     & { color: $color-ardechedrome; }
			.domain-lac       & { color: $color-lac; }
      .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }
		}
	}

	ul.menu.nav > li:after {
		float: right;
		content: "";
		height: 12px;
		width: 1px;
		border: 1px solid rgba(#fff, 0.8);
		margin-top: 2px;
	}

	ul.menu.nav li:first-child:after {
		content: none;
	}
}

/*
########      ###     ##    ##   ##    ##   ########   ########
##     ##    ## ##    ###   ##   ###   ##   ##         ##     ##
##     ##   ##   ##   ####  ##   ####  ##   ##         ##     ##
########   ##     ##  ## ## ##   ## ## ##   ######     ########
##     ##  #########  ##  ####   ##  ####   ##         ##   ##
##     ##  ##     ##  ##   ###   ##   ###   ##         ##    ##
########   ##     ##  ##    ##   ##    ##   ########   ##     ##
 */

#navbar {
	border-radius: 0px;
	border: none;
	margin-bottom: 0;
	background: transparent;
}

#navbar {
	min-height: 145px;

	@media (min-width: 768px) {
		background: url(../img/theme2018/header-greta-electronique.jpg) center center no-repeat;
		background-size: cover;
		min-height: 230px;

		&.banner-version-1 {
			background: url(../img/theme2018/header-greta-electricite.jpg) center center no-repeat;
		}
		&.banner-version-2 {
			background: url(../img/theme2018/header-greta-patisserie.jpg) center center no-repeat;
		}
		&.banner-version-3 {
			background: url(../img/theme2018/header-greta-electrotechnique.jpg) center center no-repeat;
		}
		&.banner-version-4 {
			background: url(../img/theme2018/header-greta-soudure.jpg) center center no-repeat;
		}
		&.banner-version-5 {
			background: url(../img/theme2018/header-greta-truelle.jpg) center center no-repeat;
		}
	}

	@media (min-width: 991px) {
		min-height: 320px;
	}
}

#navbar a.logo {

	@media (min-width: 768px) {
		display: block;
		width: 100%;
		max-width: 320px;
		margin: 0;
		padding: 0 20px 20px 20px;

		background-color: #fff;
	}

}

#navbar a.logo img {
	max-width: 100%;
}

@media (min-width: 768px) and (max-width: 990px) {
	#navbar a.logo img {
	}
}

@media (min-width: 768px) {
	#navbar .navbar-header {
		min-height: 145px;
	}
}


/*
##      ## ##    ##   ####  ##      ## ##    ##    ######
##  ##  ##  ##  ##     ##   ##  ##  ##  ##  ##    ##    ##
##  ##  ##   ####      ##   ##  ##  ##   ####     ##
##  ##  ##    ##       ##   ##  ##  ##    ##      ##   ####
##  ##  ##    ##       ##   ##  ##  ##    ##      ##    ##
##  ##  ##    ##       ##   ##  ##  ##    ##      ##    ##
 ###  ###     ##      ####   ###  ###     ##       ######
*/



/*
##    ##      ###     ##     ##
###   ##     ## ##    ##     ##
####  ##    ##   ##   ##     ##
## ## ##   ##     ##  ##     ##
##  ####   #########   ##   ##
##   ###   ##     ##    ## ##
##    ##   ##     ##     ###
*/

#page-header {
	padding: 0;
	position: relative;
	z-index: 1;
}

.region-header {
	position: relative;
	@include clearfix;

	// VIVARAIS ET
	#block-block-100 {
		display: none;
	}

	a {
		text-decoration: none !important;
	}

	#block-menu-menu-vous-etes {
		position: relative;
		height: 64px;

		@media (min-width: 768px) {
			background: none;
		}

		@media (min-width: 991px) {
			padding-left: 34%;
		}

		@media (min-width: 1200px) {
			padding-left: 38%;
		}


		.block-title {
			display: inline-block;
			height: 64px;
			line-height: 64px;
			padding: 0 40px 0 20px;
			margin: 0;
			position: relative;
			overflow: hidden;
			float: left;

			background-color: $color-top-bar;

			font-family: $fontalt;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 18px;
			color: #fff;

			&:before,
			&:after {
				content: "";
				display: block;
				width: 64px;
				height: 64px;
				position: absolute;
				top: -32px;
				right: -44px;

				background-color: $color-2;

				transform: rotate(45deg);
			}

			&:after {
				top: 32px;
			}
		}

		.menu.nav {
			background-color: $color-2;
			display: flex;
			justify-content: space-between;

			& > li {
				display: inline-block;

				& > a {
					height: 64px;
					padding: 0 5px;
					line-height: 64px;
					font-weight: 400;
					color: #fff;
					text-transform: uppercase;


					&:hover,
					&:focus,
					&:active {
						background: transparent;
						color: $color-3;
					}
				}
			}
		}
	}

	& > section#block-menu-menu-menu-recherchez {

		.nav {
			display: inline-block;
			position: absolute;
			bottom: 100%;
			z-index: 20000;

			background-color: $color-1;
			.domain-savoie    & { background-color: $color-savoie; }
			.domain-vivarais  & { background-color: $color-provence; }
			.domain-grenoble  & { background-color: $color-grenoble; }
			.domain-arve      & { background-color: $color-arve; }
			.domain-nord      & { background-color: $color-nordisere; }
			.domain-ardechedrome     & { background-color: $color-ardechedrome; }
			.domain-lac       & { background-color: $color-lac; }
      .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }

			@media (min-width: 990px) {
				bottom: auto;
				top: 50%;
				left: 30px;

				transform: translateY(-50%);
			}
		}

		a {
			display: block;
			width: 290px;
			padding: 15px 80px 15px 20px !important;
			position: relative;

			font-size: 18px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 600;
			letter-spacing: 0.1em;
			text-decoration: none;

			box-shadow: 10px 0 20px -2px rgba(#000, 0.2);

			transition: background-color 0.3s ease-in-out;

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background-color: #fff;
				opacity: 0.1;

				transform-origin: 50% 0%;
				transform: scaleY(0);
				transition: transform 0.2s ease-in-out;
			}

			&:after {
				content: "";
				display: block;
				width: 35px;
				height: 34px;
				position: absolute;
				top: 50%;
				right: 20px;

				background: transparent url('../img/theme2018/picto-search.png') 0 0 no-repeat;
				background-size: 35px 34px;

				transform-origin: 50% 50%;
				transform: translateY(-50%);
				transition: transform 0.3s ease-in-out;
			}

			&:hover,
			&:focus,
			&:active {
				background-color: $color-1;
				.domain-savoie    & { background-color: $color-savoie; }
				.domain-vivarais  & { background-color: $color-provence; }
				.domain-grenoble  & { background-color: $color-grenoble; }
				.domain-arve      & { background-color: $color-arve; }
				.domain           & { background-color: $color-nordisere; }
				.domain-ardechedrome     & { background-color: $color-ardechedrome; }
				.domain-lac       & { background-color: $color-lac; }
        .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }

				&:before {
					transform: scaleY(1);
				}

				&:after {
					transform: translateY(-50%) scale(1.05);
				}
			}

			@media (min-width: 991px) {
				width: 300px;
				padding: 30px 80px 30px 30px !important;

				font-size: 18px;

				box-shadow: 0 20px 40px -10px rgba(#000, 0.2);
			}

			@media (min-width: 1200px) {
				width: 420px;
				padding: 30px 150px 30px 60px !important;

				font-size: 20px;

				box-shadow: 0 20px 40px -10px rgba(#000, 0.2);
			}

		}
	}

	& > section#block-block-226, // Arve
	& > section#block-block-220, // Grenoble
	& > section#block-block-223, // Nord Isere
	& > section#block-block-160, // Savoie
	& > section#block-block-222, // Viva 5
	& > section#block-block-225, // Vivarais Provence
	& > section#block-block-221, // Lac
  & > section#block-block-221, // savoiehautesavoie

	& > section#block-block-265 {
		padding: 0;
		margin-top: 0 !important;

		background-color: $color-top-bar;

		.container {
			padding: 0 !important;
		}

		.navbar-default {
			border-radius: 0;
			min-height: auto;
			border: none;

			@media (min-width: 768px) {
				background: none;
			}

			@media (min-width: 991px) {
				padding-left: 34%;
			}

			@media (min-width: 1200px) {
				padding-left: 40%;
			}

			.navbar-header {
				height: 64px;

				.navbar-brand {
					display: block;
					height: 64px;
					line-height: 64px;
					padding: 0 30px 0 20px;
					position: relative;
					overflow: hidden;
					float: none;

					background-color: $color-top-bar;

					font-family: $fontalt;
					font-weight: 700;
					text-decoration: none;
					color: #fff;

					&:before,
					&:after {
						content: "";
						display: block;
						width: 64px;
						height: 64px;
						position: absolute;
						top: -32px;
						right: -44px;

						background-color: $color-2;

						transform: rotate(45deg);
					}

					&:after {
						top: 32px;
					}
				}
			}

			.navbar-collapse {
				padding: 0;
				// width: 66%;
				background-color: $color-2;


				.navbar-nav:last-of-type {
					display: none;
				}

				.dropdown-toggle {
					line-height: 64px;
					padding: 0 20px;
					position: relative;

					font-family: $fontalt;
					font-weight: 100;
					text-decoration: none;

					&:before {
						content: "";
						display: block;
						height: 3px;
						position: absolute;
						top: 100%;
						left: 20px;
						right: 31px;


						background-color: #fff;

						transform-origin: 50% 50%;
						transform: translate(0, -20px) scaleX(0);
						transition: transform 0.25s ease-in-out;

					}
				}
			}
		}

		.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
			background: none;

			&:before {
				transform: translate(0, -20px) scaleX(1);
			}
		}

		.mega-dropdown-menu {
			margin-top: 0 !important;
			padding: 0 !important;

			border-radius: 0 !important;
			border: none;
			background-color: $color-2 !important;

			&:before,
			&:after {
				display: none;
			}

			a {
				text-decoration: none;
			}

			.container {
				padding: 50px 30px 30px 30px !important;

				.scroll > .row {
					padding: 0 !important;
					margin: 0 !important
				}
			}

			.thumbnails {
				@include clearfix;
				margin: 0 -10px;

				.col-md-3 {

					padding: 0 10px !important;
				}

				.col-md-12 {
					padding: 0 !important;
				}
			}
			.thumbnail {
				padding: 5px !important;
				border: none;
				border-radius: 0;

				.carousel {
					margin-left: 0 !important;

					.item {

						padding: 0 10px;

						picture {
							display: block;
							margin: 0 -10px 20px -10px;
						}

						h3 {
							margin: 0 0 10px 0;
							padding: 0;
						}

						p {
							padding-left: 0 !important;
						}

						p.rtecenter {
							text-align: left;
						}
					}

					.btn {
						margin: 10px 0 0 0;
						width: auto;
						max-width: 100%;
						display: block;
					}
				}

				p[align="center"] {
					text-align: left;

					.btn:first-of-type {
						margin-top: 10px;
					}
				}
			}

			.acad-bg-rose-1-logo {
				margin: -5px -5px 5px -5px !important;
				padding: 15px !important;

				background-color: $color-top-bar;
				font-weight: 600;
				font-size: 16px;

			}
		}
	}
}

#page-header {
	section#block-block-100 { // bandeau supplémentaire lac, savoiehautesavoie et vivarais
		@include clearfix;
		padding: 30px 0 50px 0 !important;
		margin: 0 !important;
		float: left;
	}

	section#block-menu-menu-vous-etes { // vivarais et lac, savoiehautesavoie
		width: 100%;
		position: relative;
		float: left;

		background-color: $color-top-bar;

	}

	section#block-block-226, // Arve
	section#block-block-220, // Grenoble
	section#block-block-223, // Nord Isere
	section#block-block-160, // Savoie
	section#block-block-222, // Viva 5,
	section#block-block-265 { // Principal
		.menu.nav li {

			a:hover {
				color: #c91447;
			}

			.dropdown-menu {
				top: 100%;
				border-radius: 0;
				border: 0;
				background: #b5b6bb;
				box-shadow: none;
				color: #3e474e;
				padding: 10px 0;
				max-width: 210px;

				a {
					color: #3e474e;
					background: transparent;
					text-transform: inherit;
					display: block;
					white-space: inherit;
					margin: 5px 0;
					cursor: pointer;
				}

				li {
					clear: both;
					padding: 0;
					width: 100%;

					&:after {
						content: none;
					}
				}
			}
		}
	}
}

#block-menu-menu-top-navigation {
	position: absolute;
	top: 20px;
	right: 30px;

	@media (min-width: 991px) {
		top: 30px;
	}

	.menu.nav {
		li {
			background: none !important;
			float: left;
		}

		a {
			padding: 5px 20px !important;

			background: rgba(#fff, 0.8) !important;

			font-family: $fontalt;
			font-weight: 600;
			font-size: 13px;
			text-decoration: none;
			color: $color-1 !important;
			.domain-savoie    & { color: $color-savoie !important; }
			.domain-vivarais  & { color: $color-provence !important; }
			.domain-grenoble  & { color: $color-grenoble !important; }
			.domain-arve      & { color: $color-arve !important; }
			.domain-nord      & { color: $color-nordisere !important; }
			.domain-ardechedrome     & { color: $color-ardechedrome !important; }
			.domain-lac       & { color: $color-lac !important; }
      .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie !important; }

			&:hover,
			&:focus,
			&:active {
				color: $color-3 !important;
			}
		}
	}
}

/*
########    #######   ##     ##     ###     ####  ##    ##
##     ##  ##     ##  ###   ###    ## ##     ##   ###   ##
##     ##  ##     ##  #### ####   ##   ##    ##   ####  ##
##     ##  ##     ##  ## ### ##  ##     ##   ##   ## ## ##
##     ##  ##     ##  ##     ##  #########   ##   ##  ####
##     ##  ##     ##  ##     ##  ##     ##   ##   ##   ###
########    #######   ##     ##  ##     ##  ####  ##    ##
*/

.view-domain-nav {
	li {
		width: 50%;
		padding: 0 0 20px 0;
		float: left;
		list-style-type: none;
		text-align: center;

		@media (min-width: 991px) {
			width: 33%;
			padding: 0 0 30px 0;
		}

		a {
			display: block;
			text-align: center;
		}

		img {
			margin: 0 auto;
		}
	}
}

/*
 ######    ####  ########   ########   ########      ###     ########
##    ##    ##   ##     ##  ##         ##     ##    ## ##    ##     ##
##          ##   ##     ##  ##         ##     ##   ##   ##   ##     ##
 ######     ##   ##     ##  ######     ########   ##     ##  ########
      ##    ##   ##     ##  ##         ##     ##  #########  ##   ##
##    ##    ##   ##     ##  ##         ##     ##  ##     ##  ##    ##
 ######    ####  ########   ########   ########   ##     ##  ##     ##
*/



[role="complementary"] {
	@include clearfix;

	.block-block {
		padding: 0;

		p:last-of-type {
			margin: 0 !important;
		}
		&.color-sidebar-acad {
			padding: 15px;
			background-color: $color-1;
		}
		&.color-sidebar-arve {
			padding: 15px;
			background-color: $color-arve;
		}
		&.color-sidebar-grenoble {
			padding: 15px;
			background-color: $color-grenoble;
		}
		&.color-sidebar-lac {
			padding: 15px;
			background-color: $color-lac;
		}
    &.color-sidebar-savoiehautesavoie {
      padding: 15px;
      background-color: $color-savoiehautesavoie;
    }
		&.color-sidebar-savoie {
			padding: 15px;
			background-color: $color-savoie;
		}
		&.color-sidebar-nord {
			padding: 15px;
			background-color: $color-nordisere;
		}
		&.color-sidebar-ardechedrome {
			padding: 15px;
			background-color: $color-ardechedrome;
		}
		&.color-sidebar-vivarais {
			padding: 15px;
			background: $color-provence;
		}


	}

	.region-sidebar-first.well {
		& > section {
			float: left;
			margin: 0 0 30px 0;
		}
	}

	#block-dafco-customize-dafco-cta-metiers,
	#block-dafco-customize-dafco-cta-formations {
		width: 100%;
		background-size: cover;
		background-position: center center;
		margin: 0 0 30px 0;
		padding: 20px;


		color: $color-3;
		font-size: 16px;
		font-weight: 400;

		@media (min-width: 768px) {
			text-align: left;
		}

		@media (min-width: 991px) {
			text-align: center;
		}

		h2 {
			text-transform: uppercase;
			color: $color-3;
			font-size: 30px;
			font-weight: 600;
			margin: 0 0 20px 0;

			@media (min-width: 768px) {
				font-size: 24px;
			}

			@media (min-width: 991px) {
				font-size: 30px;
			}


		}

		.cta-link {
			@extend .btn;
			@extend .btn-default;
			display: block !important;
			margin-top: 30px;
			min-width: 80% !important;
			font-size: 20px;
			text-align: center;
		}
	}

	#block-dafco-customize-dafco-cta-formations {
		background-image: url('../img/theme2018/bg-sidebar-formation.jpg');
	}

	#block-dafco-customize-dafco-cta-metiers {
		background-image: url('../img/theme2018/bg-sidebar-metier.jpg');
	}
}

.region-sidebar-second {

	& > .block {
		margin: 0 0 30px 0 !important;
	}
	#block-block-272,
	#block-block-318 {
		border: 1px solid $color-4;
		// margin: 0 0 30px 0 !important;
	}

	#block-block-318 {
		.rtecenter {
			margin: 0;
		}
	}
}

.block-nodule-news {
	padding: 15px;
	background-color: $color-4;

	h2.block-title {
		font-family: $fontalt;
		color: $color-3 !important;
		font-size: 22px;
		font-weight: 600;
	}

	a.news-title {
		color: $color-3 !important;
		font-size: 14px;
		font-weight: 600;
	}

	span.date {
		display: block;
		margin: 0 0 10px 0;
		color: $color-1;
		font-weight: 600;
		font-style: italic;
	}
}

.breadcrumb {
	display: block;
	width: auto;
	margin: -30px 15px 30px 0;
	border: none;

	li + li:before {
		content: ">";
		color: $color-2;
		font-weight: 400;
	}

	a {
		color: $color-2;
		font-size: 13px;
		font-weight: 600;
	}
}

.region-content {
	@include clearfix;

	.block-block {
		margin: 0 0 30px 0 !important;
		padding: 0;

		p:last-of-type {
			margin: 0;
		}
	}

	#block-block-254 {
		margin: 0 !important;

		p[align="center"] {
			margin: 0 0 30px 0;
		}
	}
}

.bloc-content {
	@include clearfix;
	max-width: 800px;

	h1.page-header {
		font-weight: 600;
		margin: 0 0 20px 0 !important;
		padding: 0 !important;
		color: $color-3;

		@media (min-width: 991px) {
			margin: 0 0 30px 0 !important;

		}

	}

	.nav-tabs {
		& > li {
			margin: 0;
		}

		&.list-map {

			& > li > a {
				border-radius: 2px;
				padding: 10px 20px;

				background-color: $color-4 !important;


			}

			& > li.active > a {
				background-color: $color-1 !important;
				.domain-savoie    & { background-color: $color-savoie !important; }
				.domain-vivarais  & { background-color: $color-provence !important; }
				.domain-grenoble  & { background-color: $color-grenoble !important; }
				.domain-arve      & { background-color: $color-arve !important; }
				.domain-nord      & { background-color: $color-nordisere !important; }
				.domain-ardechedrome     & { background-color: $color-ardechedrome !important; }
				.domain-lac       & { background-color: $color-lac !important; }
        .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie !important; }
				box-shadow: none;
				color: #fff;
			}
		}
	}

	.field-type-image {
		margin-bottom: 20px;
	}

	.field-name-field-domaine {
		margin-bottom: 10px;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 18px;
	}

	.field-name-field-date-formation,
	.field-name-field-entier {
		display: block;
		margin-bottom: 20px;

		// @media (min-width: 991px) {
		// 	display: block;

		// 	width: 46%;
		// 	float: left;
		// }

		.field-label {
			display: block;
			margin-bottom: 10px;
			float: none;
			font-weight: 600;
			font-size: 14px;
		}
	}

	.field-name-field-entier {
		margin-left: 8%;
	}

	.field-name-body {
		clear: left;

		.field-label {
			display: block;
			margin-bottom: 20px;
			font-weight: 600;
			font-size: 18px;
		}
	}


}
.bloc-greta-home--mobile {
	h1 {
		display: block;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-size: 42px !important;
		text-align: left !important;
		font-weight: 600;

	}

	ul {
		display: flex;
		justify-content: space-between;
	}

	li {
		display: inline-block;
	}
}

.bloc-greta-home {
	@include clearfix;
	display: block;
	padding: 20px;
	margin-bottom: 30px;

	background: $color-4 url('../img/theme2018/bg-antenne.png') no-repeat right center;

	.title {
		margin: 0 0 20px 0;

		font-weight: 600;
		font-size: 24px !important;
		color: $color-3;
		text-align: left !important;
	}

	.greta-home__list {
		li {
			display: block;
			padding: 2px 0;

			list-style-type: none;
		}

		a {
			display: inline-block;
			padding: 0 15px 0 0;
			position: relative;

			font-size: 16px;
			font-weight: 100;
			color: $color-2;
			text-decoration: none;

			transition: color 0.3s ease-in-out;

			&:after {
				content: "";
				display: block;
				width: 7px;
				height: 12px;
				position: absolute;
				top: 50%;
				right: 0;

				background: transparent url('../img/theme2018/arrow-grey.png') 0 0 no-repeat;
				background-size: 7px 12px;

				transform: translate(0, -50%);
				transition: transform 0.1s ease-in-out, opacity 0.3s ease-in-out;
			}

			&:hover,
			&:focus,
			&:active {
				color: $color-1;
				.domain-savoie    & { color: $color-savoie; }
				.domain-vivarais  & { color: $color-provence; }
				.domain-grenoble  & { color: $color-grenoble; }
				.domain-arve      & { color: $color-arve; }
				.domain-nord      & { color: $color-nordisere; }
				.domain-ardechedrome     & { color: $color-ardechedrome; }
				.domain-lac       & { color: $color-lac; }
        .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }

				&:after {
					transform: translate(5px, -50%);
				}
			}
		}
	}
}

.catalogue {
	background: red;
	padding: 0;

	h2 {
		font-family: $fontalt;
		font-weight: 700;
		font-size: 16px;
		word-break: initial;
		color: $color-3;
	}

	.btn-default {
		color: #fff !important;
	}
}

footer.footer {
	background: url(../img/theme2018/footer.png) center top no-repeat;
	min-height: 220px;
	position: relative;

	ul.menu.nav {
		padding: 0 20px 10px 0;

		li {
			&:after {
				display: none;
			}
		}

		a {
			padding: 0 10px;
			font-weight: 600;
			font-size: 14px !important;

			transition: color 0.3s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				color: $color-1 !important;
				.domain-savoie    & { color: $color-savoie !important; }
				.domain-vivarais  & { color: $color-provence !important; }
				.domain-grenoble  & { color: $color-grenoble !important; }
				.domain-arve      & { color: $color-arve !important; }
				.domain-nord      & { color: $color-nordisere !important; }
				.domain-ardechedrome     & { color: $color-ardechedrome !important; }
				.domain-lac       & { color: $color-lac !important; }
        .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie !important; }
			}
		}
	}
}

/*
##    ##   ########   ##      ##  ######
###   ##   ##         ##  ##  ## ##    ##
####  ##   ##         ##  ##  ## ##
## ## ##   ######     ##  ##  ##  ######
##  ####   ##         ##  ##  ##       ##
##   ###   ##         ##  ##  ## ##    ##
##    ##   ########    ###  ###   ######
*/

article.node-news {
	.categorie {
		background-color: $color-4;
		color: $color-2;
	}

	.date {
		color: $color-1;
		.domain-savoie    & { color: $color-savoie; }
		.domain-vivarais  & { color: $color-provence; }
		.domain-grenoble  & { color: $color-grenoble; }
		.domain-arve      & { color: $color-arve; }
		.domain-nord      & { color: $color-nordisere; }
		.domain-ardechedrome     & { color: $color-ardechedrome; }
		.domain-lac       & { color: $color-lac; }
    .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }

		font-weight: 600;
		font-style: italic;
	}

	.media-heading {
		a {
			font-family: $fontalt !important;
			font-weight: 600;
			font-size: 18px;
			color: $color-3;
		}
	}

	.reso-bg-lien-1 {
		margin-top: 40px;
		padding: 0;
		background-color: $color-1;

		.domain-savoie    & { background-color: $color-savoie; }
		.domain-vivarais  & { background-color: $color-provence; }
		.domain-grenoble  & { background-color: $color-grenoble; }
		.domain-arve      & { background-color: $color-arve; }
		.domain-nord      & { background-color: $color-nordisere; }
		.domain-ardechedrome     & { background-color: $color-ardechedrome; }
		.domain-lac       & { background-color: $color-lac; }
    .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }

		a {
			display: block;
			padding: 20px;
			text-align: center;
			color: #fff;
			font-size: 14px;
			font-weight: 600;

			transition: background-color 0.3s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				background-color: $color-3;
			}
		}
	}
}

/*
##     ##   #######   ##     ##  ########
##     ##  ##     ##  ###   ###  ##
##     ##  ##     ##  #### ####  ##
#########  ##     ##  ## ### ##  ######
##     ##  ##     ##  ##     ##  ##
##     ##  ##     ##  ##     ##  ##
##     ##   #######   ##     ##  ########
*/

.block-recrutement {
	display: none;
	margin-bottom: 30px;

	background-color: $color-2;

	@include clearfix;
	display: block;
	padding: 20px 20px 20px 160px;
	position: relative;
	color: #fff;
	transition: background-color 0.3s ease-in-out;
	text-decoration: none;

	a {
		color: #fff;
		text-decoration: none;
	}

	p {
		display: block;
		margin: 0;

		font-size: 18px;
		font-weight: 900;


		@media (max-width: 767px) {
			br {
				display: none;
			}
		}
	}
}

.block-recrutement__year {
	display: inline-block;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 50%;
	left: 20px;

	font-size: 50px;
	font-weight: 900;

	transform: translateY(-50%);
}

.block-survey {
	margin-bottom: 30px;
	padding: 30px 40% 30px 20px;

	background: transparent url('../img/theme2018/bg-home-projet.jpg') 50% 50% no-repeat;
	background-size: cover;

	h4 {
		display: block;
		margin: 0 0 20px 0;
		color: #fff;
		font-weight: 700;
		font-size: 20px;
	}

	p {
		margin: 0 0 20px 0 !important;
		color: #fff;
		font-weight: 700;
		font-size: 14px;
	}

	.btn {
		font-size: 20px;
	}
}

.callout-dark {
	margin: 0 15px !important;
	padding: 20px !important;
}

.prendre-contact {
	padding: 0;
	font-weight: 600 !important;

	a {
		font-weight: 700 !important;
		display: block;
		padding: 20px !important;
		font-size: 14px;
		text-decoration: none;
	}

	background: $color-1;
	.domain-savoie    & { background: $color-savoie; }
	.domain-vivarais  & { background: $color-provence; }
	.domain-grenoble  & { background: $color-grenoble; }
	.domain-arve      & { background: $color-arve; }
	.domain-nord      & { background: $color-nordisere; }
	.domain-ardechedrome     & { background: $color-ardechedrome; }
	.domain-lac       & { background: $color-lac; }
  .domain-savoiehautesavoie       & { background: $color-savoiehautesavoie; }
}


/*
########    #######   ########   ##     ##   ######
##         ##     ##  ##     ##  ###   ###  ##    ##
##         ##     ##  ##     ##  #### ####  ##
######     ##     ##  ########   ## ### ##   ######
##         ##     ##  ##   ##    ##     ##        ##
##         ##     ##  ##    ##   ##     ##  ##    ##
##          #######   ##     ##  ##     ##   ######
*/

.form-control {
	@include input_mobile();
	border-radius: 0;
}

form {
	.form-item  {
		display: block;
		@include clearfix;

		input[type="text"] {
			width: 100%;
			height: auto;
			@include input_mobile();
			padding: 10px;
			border: 1px solid #dedede;
			box-shadow: none;
		}

		select {
			width: 100%;
			height: auto;
			@include input_mobile();
			padding: 10px 30px 10px 10px;
			margin-bottom: 10px;

			background: #fff url('../img/theme2018/select-arrows.png') right 8px center no-repeat;
			border: 1px solid #dedede;
			background-size: 10px 14px;
			box-shadow: none;
		}
	}
}

.liste-formation {
	#header_checkbox {
		box-shadow: none;
		padding: 0;

		.col-md-6 {
			width: 100%;

			&:first-of-type {
				padding: 10px 20px;
			}
		}
	}

	h3 {
		color: $color-1;
		.domain-savoie    & { color: $color-savoie; }
		.domain-vivarais  & { color: $color-provence; }
		.domain-grenoble  & { color: $color-grenoble; }
		.domain-arve      & { color: $color-arve; }
		.domain-nord      & { color: $color-nordisere; }
		.domain-ardechedrome     & { color: $color-ardechedrome; }
		.domain-lac       & { color: $color-lac; }
    .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }
		font-weight: 600;
		font-size: 20px;
	}

	h4 {
		color: $color-3;
		font-size: 18px;
		font-weight: 600;
	}

	.article-formation {
		padding: 10px 20px 10px 40px;
		position: relative;
		box-shadow: none;

		border-radius: 2px;
		background-color: $color-4;

		&:hover,
		&:focus,
		&:active {

		}

		.checkbox_catalogue {
			position: absolute;
			top: 5px;
			left: 15px;

		}

		p:not(.lieux) a {
			display: block;
			margin-bottom: 5px;
			color: $color-3;
			font-weight: 600;


		}
	}
}

form#views-exposed-form-recherche-une-formation-gretas-page,
form#views-exposed-form-recherche-une-formation-gretas-page-filtre-simple-reseau-gretas,
form#views-exposed-form-formations-decouvrir-page,
form#views-exposed-form-metiers-recrutent-page,
form#views-exposed-form-recherche-une-formation-page {
	background-color: darken($color-4, 5%);
	margin: 0 0 30px 0;
	padding: 20px;

	.views-exposed-form {
		@include clearfix;
		margin: 0;
	}

	.views-exposed-widgets,
	.views-exposed-widget {
		display: block;
		width: 100%;
		margin: 0;
		padding: 0;

		&#edit-recherche-une-formation-domaine-tid-wrapper,
		&#edit-recherche-une-formation-domaine-tid-ref-wrapper {
			margin-bottom: 10px;

			select:not(.element-invisible) {
				@media (min-width: 991px) {
					width: 48%;
					float: left;

					&.shs-select-level-2 {
						margin-left: 4%;
					}
				}
			}
		}

		&#edit-recherche-une-formation-domaine-tid-ref-wrapper {
			margin: 0;
		}

		&#edit-field-code-postal-value-wrapper {
			margin-bottom: 20px;

			input[type="text"] { width: 48%; }
		}

		#edit-field-departement-tid-wrapper,
		#edit-departement-wrapper {
			@media (min-width: 991px) {
				width: 48%;
				float: left;
			}
		}

		#edit-field-greta-tid-wrapper {
			@media (min-width: 991px) {
				width: 48%;
				margin-left: 4%;
				float: left;
			}
		}
	}

	label {
		display: block;
		padding: 10px 0 0 0;
		font-weight: 600;
		font-size: 16px;
	}

	#edit-combine-wrapper {
		display: block;
		float: none;
		padding: 0;

		label {
			padding: 0 0 10px 0;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 20px;
		}

		.description {
			display: block;
			padding: 5px 0 0 0;
			font-style: italic;
		}
	}



	.views-submit-button {
		display: block;
		padding: 0;
		float: left;
		text-align: right;

		button,
		.btn-info {
			display: block !important;
			width: 100% !important;
			margin: 20px 0 0 0 !important;
			padding: 15px 40px;

			border-radius: 2px;
			border: none;
			background-color: $color-1;

			.domain-savoie    & { background-color: $color-savoie; }
			.domain-vivarais  & { background-color: $color-provence; }
			.domain-grenoble  & { background-color: $color-grenoble; }
			.domain-arve      & { background-color: $color-arve; }
			.domain-nord      & { background-color: $color-nordisere; }
			.domain-ardechedrome     & { background-color: $color-ardechedrome; }
			.domain-lac       & { background-color: $color-lac; }
      .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }

			text-align: center;
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 600;

			transition: background-color 0.3s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				background-color: $color-3;
			}

			&.js-hide {
				display: none !important;
			}
		}
	}
}

form#views-exposed-form-formations-decouvrir-page {
	margin-top: 0;
}


/*
##         ####   ######    ########   ####  ##    ##    ######
##          ##   ##    ##      ##       ##   ###   ##   ##    ##
##          ##   ##            ##       ##   ####  ##   ##
##          ##    ######       ##       ##   ## ## ##   ##   ####
##          ##         ##      ##       ##   ##  ####   ##    ##
##          ##   ##    ##      ##       ##   ##   ###   ##    ##
########   ####   ######       ##      ####  ##    ##    ######
*/

.view-formations-decouvrir,
.view-metiers-recrutent {
	.view-content {
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
		}

		article {
			@include clearfix;
			margin: 0 0 30px 0;
			padding: 20px;

			background-color: $color-4;

			@media (min-width: 991px) {
				//margin: 0 0 50px 0;
			}

			& > header {
				margin-bottom: 20px;

				a {
					display: block;
					text-transform: uppercase;
					font-size: 18px;
					font-weight: 600;

					color: $color-1;

					@media (min-width: 991px) {
						font-size: 20px;
					}

					transition: color 0.2s ease-in-out;

					&:after {
						content: "→";
						display: inline-block;
						margin-left: 8px;
						position: relative;
						top: -2px;

						transition: transform 0.2s ease-in-out;
					}

					.domain-savoie    & { color: $color-savoie; }
					.domain-vivarais  & { color: $color-provence; }
					.domain-grenoble  & { color: $color-grenoble; }
					.domain-arve      & { color: $color-arve; }
					.domain-nord      & { color: $color-nordisere; }
					.domain-ardechedrome     & { color: $color-ardechedrome; }
					.domain-lac       & { color: $color-lac; }
          .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }

					&:hover,
					&:focus,
					&:active {
						color: $color-3;

						&:after {
							transform: translate(5px, 0);
						}
					}
				}
			}

			.field {
				display: block;
				margin: 0 0 10px 0;
			}

		}
	}
}

.view-recherche-une-formation,
.view-recherche-une-formation-gretas {

	.view-content {
		a {
			text-decoration: none;
			color: $color-3;

			transition: color 0.2s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				color: $color-1;

				.domain-savoie    & { color: $color-savoie; }
				.domain-vivarais  & { color: $color-provence; }
				.domain-grenoble  & { color: $color-grenoble; }
				.domain-arve      & { color: $color-arve; }
				.domain-nord      & { color: $color-nordisere; }
				.domain-ardechedrome     & { color: $color-ardechedrome; }
				.domain-lac       & { color: $color-lac; }
        .domain-savoiehautesavoie       & { color: $color-savoiehautesavoie; }
			}
		}

		h3 {
			margin-top: 20px;
			padding-top: 20px;

			&:not(:first-of-type) {
				border-top: 1px solid $color-4;
			}
		}

		.views-row {
			display: inline-block;

			a {
				display: inline-block;
				margin-left: 20px;
			}
		}
	}
}

.node-formation-decouvrir {
	.field-name-field-departement,
	.field-name-field-greta {
		// float: left;

		.field-item {
			display: inline-block;
			margin-right: 10px;
		}

		.vocabulary-departement {
			display: inline-block;

			h2 {
				display: inline;
				margin: 0;
				font-size: 14px;

				a {
					text-decoration: none;
				}
			}
		}
	}

	.field-name-field-greta {
		display: block;
		// margin-top: 20px !important;
		// float: left;
		clear: left;
	}
}




.embedded-video {
	position: relative;
	margin-bottom: 30px;
	padding-top: 57%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.video-embed-description {
	display: block;
	padding: 10px;
	font-style: italic;
}

.embedded-video + .video-embed-description {
	display: none
}

.group-nombre-offres {
	@include clearfix;
	padding: 0;
	margin-bottom: 30px;
	background-color: $color-4;
	border-radius: 2px;
	position: relative;

	@media (min-width: 768px) {
		&:before {
			content: "";
			display: block;
			width: 50%;
			position: absolute;
			left: 50%;
			top: 0;
			bottom: 0;
			z-index: 0;

			background-color: darken($color-4, 5%);
		}
	}

	.group-offres-au,
	.field-name-field-link-2  {
		padding: 20px;
		margin: 0;
		text-align: center;
		width: 100%;
		position: relative;

		@media (min-width: 768px) {
			width: 50%;
			float: left;
		}
	}

	.field-name-field-date {
		display: block;
		text-align: center;
		width: 100%;
		float: none !important;

		.field-label {
			display: inline-block;
			float: none;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}

		.field-items {
			display: inline-block;
			float: none;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}

	.field-type-number-integer {
		width: 100%;
		margin: 0;
		float: none;

		.field-item {
			font-size: 24px;
			font-weight: 600;

			&:after {
				display: block;
				content: "en Auvergne Rhône-Alpes";
				font-weight: 400;
				font-size: 20px;
			}
		}
	}

	.field-name-field-link-2 {
		font-size: 24px;
		font-weight: 600;

		background-color: darken($color-4, 5%);

		&:before {
			display: block;
			content: "rdv sur";
			font-weight: 400;
			font-size: 20px;
		}
	}
}

.field-name-field-link a[href="http://www.gretaformation.fr"],
.field-name-field-link a[href="https://www.gretaformation.fr"],
.field-name-field-link a[href="https://new.gretaformation.fr"] {
	display: block;
	margin-bottom: 30px;
	padding: 20px;

	text-align: center;
	font-weight: 600;
	font-size: 24px;
	text-decoration: none;
	color: #fff;
	text-transform: uppercase;

	background-color: $color-1;
	border-radius: 2px;

	.domain-savoie    & { background-color: $color-savoie; }
	.domain-vivarais  & { background-color: $color-provence; }
	.domain-grenoble  & { background-color: $color-grenoble; }
	.domain-arve      & { background-color: $color-arve; }
	.domain-nord      & { background-color: $color-nordisere; }
	.domain-ardechedrome     & { background-color: $color-ardechedrome; }
	.domain-lac       & { background-color: $color-lac; }
  .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }

	transition: background-color 0.3s ease-in-out;

	&:hover,
	&:focus,
	&:active {
		background-color: $color-3;
	}
}

.field-name-field-offre-link,
.field-name-field-autres-formations {
	@include clearfix;
	display: block;
	padding: 20px;

	background-color: $color-4;
	border-radius: 2px;
	border: none !important;

	& > .field-label {
		display: block;
		font-weight: 600;
		font-size: 24px;
		text-transform: uppercase;
	}
}

.field-name-field-offre-link {
	& > .field-items {
		@include clearfix;

		& > .field-item {
			@media (min-width: 991px) {
				width: 30%;
				float: left;
				margin-left: 5%;

				&:nth-of-type(3n+1) {
					margin-left: 0;
				}

				// &:not(:first-of-type) {

				// }
			}

			a {
				display: block;
				width: 100%;

				img {
					width: 100%;
					height: auto;
				}
			}
		}

		.field-collection-view {
			border: none;
		}
	}
}

.field-name-field-autres-formations {
	.field-items {
		@include clearfix;
		padding: 1em 0 0 1em;

		& > .field-item {
			display: block;
			margin-bottom: 5px;
			padding-left: 15px;
			position: relative;

			&:before {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				position: absolute;
				top: 6px;
				left: 0;

				border-radius: 50%;
				background-color: $color-1;

				.domain-savoie    & { background-color: $color-savoie; }
				.domain-vivarais  & { background-color: $color-provence; }
				.domain-grenoble  & { background-color: $color-grenoble; }
				.domain-arve      & { background-color: $color-arve; }
				.domain-nord      & { background-color: $color-nordisere; }
				.domain-ardechedrome     & { background-color: $color-ardechedrome; }
				.domain-lac       & { background-color: $color-lac; }
        .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

.prendre-contact {

}

.group-dates-places {
	@include clearfix;
	display: block;
	margin-bottom: 30px;
	padding: 20px;

	background-color: $color-4;
	border-radius: 2px;

	& > div:last-of-type {
		margin-bottom: 0 !important;
	}

	.field-label {
		display: block;
		margin: 0 0 10px 0 !important;
		font-size: 16px !important;
		font-weight: 600;

	}
	.field-item {
		display: inline-block;
		font-size: 16px;
	}

	.field-name-field-entier {
		margin-left: 0;
	}
}


.node-partenaire-recrute > div:not(.field) {
	@include clearfix;
	margin-bottom: 30px;

	h3 {
		font-size: 18px;
		font-weight: 600;
		color: #4c4b53;
	}

	.field-name-field-lieu {
		display: inline-block;

		&:after {
			content: ", ";
			display: inline-block;
		}
	}
	.field-name-field-departement {
		display: inline-block;
	}

	.field-name-field-greta {
		display: block;
	}
}

.group-localisation {
	@include clearfix;
	margin-bottom: 30px;

	h3 {
		font-size: 18px;
		font-weight: 600;
		color: #4c4b53;
	}

	.field-name-field-lieu {
		display: block;
		margin: 0;
	}
	.field-name-field-departement {
		display: block;
		margin: 0;
	}
	.field-name-field-greta {
		display: block;
		margin: 0 !important;
	}
}

.field + .group-localisation {
	margin-top: 30px;
}

.field-name-field-contact-2 {
	margin: 30px 0;
	padding: 20px;

	background-color: $color-4;

	& > .field-label {
		margin-bottom: 20px;
		font-size: 20px;
		font-weight: 600;
	}

	.node-contact {
		.node-contact__name {
			display: block;
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
		}

		.node-contact__email,
		.node-contact__phone {
			display: block;
			margin: 5px 0;
			font-size: 14px;
		}

		.lab {
			display: inline-block;
			width: 60px;
			font-weight: 600;
		}
	}
}

.node-formation-decouvrir {
	.field-name-field-link {
		a {
			display: block;
			width: 100%;
			margin: 30px 0;
			padding: 20px;

			background-color: $color-1;
			border-radius: 2px;

			text-align: center;
			font-size: 20px;
			font-weight: 600;
			text-transform: uppercase;
			text-decoration: none;
			color: #fff;

			transition: background-color 0.2s ease-in-out;

			.domain-savoie    & { background-color: $color-savoie; }
			.domain-vivarais  & { background-color: $color-provence; }
			.domain-grenoble  & { background-color: $color-grenoble; }
			.domain-arve      & { background-color: $color-arve; }
			.domain-nord      & { background-color: $color-nordisere; }
			.domain-ardechedrome     & { background-color: $color-ardechedrome; }
			.domain-lac       & { background-color: $color-lac; }
      .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }


			&:hover,
			&:focus,
			&:active {
				background-color: $color-3;
			}


		}
	}
}

.node-partenaire-recrute {
	.field-name-field-code-fonction {
		display: block;
		margin-bottom: 30px;

		font-size: 24px;
		color: $color-3;
		font-weight: 600;
		text-transform: uppercase;
	}

	.field-name-field-entier,
	.field-name-field-certification-libelle,
	.field-name-field-code-certifinfo {
		display: inline-block;
		width: auto;
		padding: 0;
		margin: 0 10px 0 0;
		font-size: 20px;
		font-weight: 600;
	}

	fieldset.panel {
		@include clearfix;
		clear: left;
		display: block;
		margin: 30px 0;
		padding: 20px;
		position: relative;
		line-height: inherit;

		background-color: $color-4;
		box-shadow: none;
		border: none;
		border-radius: 2px;
	}

	legend.panel-heading {
		display: block !important;
		position: relative !important;
		padding: 0;
		margin: 0 0 20px 0 !important;
		line-height: 1;

		background: none;
		border: none;
		transform: none !important;

		.panel-title {
			position: relative;
			font-size: 24px !important;
			font-weight: 600;
			transform: none;

			text-decoration: none;
		}
	}

	.panel-body {
		padding: 0 !important;

		.field {
			display: inline-block;
			margin-right: 10px;

			font-size: 14px;
		}

	}
}

.group-offres-contrats {
	@include clearfix;
	margin-bottom: 30px;
	padding: 20px;

	background-color: $color-4;
	border-radius: 2px;

	.field-name-field-code-fonction {
		display: block;
		margin: 0 0 10px 0;

		.field-label {
			font-size: 16px;
			font-weight: 600;
			text-transform: none;
		}

		.field-items {
			font-size: 16px;
			font-weight: 400;
			text-transform: none;
		}
	}

	.group-nb-label {
		@include clearfix;
		display: block;
	}

	.field-name-field-entier,
	.field-name-field-code-certifinfo,
	.field-name-field-certification-libelle {
		display: inline-block;
		margin: 0 0 10px 0;

		.field-label {
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			text-transform: none;
			margin: 0;
			float: left;
		}

		.field-items {
			display: inline-block;
			float: left;
			font-size: 16px !important;
			font-weight: 400 !important;
			text-transform: none;
			margin-left: 5px;
		}
	}

	.field-name-field-code-certifinfo {
		margin: 0;
	}

	.field-name-field-certification-libelle {
		display: inline-block;
		font-size: 16px;
		font-weight: 400;
		text-transform: none;
		// margin-left: 5px;
	}
}

.block-dafco-contactform {
	display: block !important;
}


