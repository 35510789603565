@import "variables";
@import "helpers";
@import "fonts";

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*------------------------------------*/
/*BACKGROUND*/
/*------------------------------------*/



.main-container {
	background-color: #fff;
}
/*------------------------------------*/
/*FONT*/
/*------------------------------------*/
.page-header {
	border: none;
}

h1.page-header,
h1,
h2.h1 {
	color: #7e8390;
	font-size: 36px;
	border: none;
	text-transform: uppercase;
	margin-bottom: 0;
	line-height: 1;
	margin-top: 15px;
}

h2,
h1.h2 {
	font-size: 36px;
	color: #7e8390;
	margin-top: 0;
	line-height: 1;
	text-transform: inherit;
}

h2.page-header {
	margin-bottom: 0;
	padding-bottom: 0;
}

.titre-section {
	position: relative;
}

.titre-section h3:before {
	content: "";
	position: absolute;
	left: 15px;
	right: 0;
	top: 19px;
	height: 1px;
	background: #fff;
}

h4,
h3.h4 {
	color: #595e68;
	font-size: 13px;
	text-transform: uppercase;
	background: transparent;
	padding: 0;
}

@media (max-width: 767px) {
	h1.page-header, h1, h2.h1 {
		font-size: 30px;
	}
}

.jumbotron {
	padding: 0!important;
	background: transparent;
}

/*link*/
a,
a:hover,
a.btn,
a.btn:hover {
	text-decoration: none;
}



a:hover, a:focus {
	color: inherit;
}

a:focus {
	text-decoration: none;
	outline: none;
}

/*------------------------------------*/
/*GENERAL*/
/*------------------------------------*/

/*table*/

table th,
table td,
table tr,
table thead,
table tbody,
table {
	border-right: none;
	border-left: none;
}

table {
	border-color: gray;
}

table td {
	padding: 5px 10px;
}


/*formulaire*/
form {
	background: #cfccca;
	padding: 15px;
}

// form .form-control {
// 	background: #e7e6e5;
// 	border-radius: 0;
// 	border: none;
// }

form button,
form a.btn,
form button.btn-default {
	background: #fff;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
	margin-top: 2px;
}

.container-inline-date .date-padding {
	padding: 0;
}

@media(max-width:767px) {
	form.form-list-formation .row .col-sm-6:nth-of-type(1) .form-control#diplome  {
		margin-bottom: 15px;
	}
}

/*structure*/
.w20 {
	padding-left: 0px;
	padding-right: 0px;
	width: 18%;
	float: left;
}

/*breadcrumbs */
.breadcrumb {
	background: transparent;
	padding-left: 0;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 5px;
	border-bottom: 1px solid #353e49;
	  border-radius: 0;
	  float: left;width: 100%;
}

.breadcrumb>li+li:before {
	content: "\203A";
}

.breadcrumb li {
	font-weight: bold;
	color: #353e49;
}

.breadcrumb li a {
	color: #7e8390;
}

@media (max-width: 767px) {
	.breadcrumb {
		margin-top: 0;
	}
}


/*navbar */






/*sous menu*/
.nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
	background: transparent;
	position: relative;
}


.glyphicon-chevron-right {
	display: none;
}








/*sous-sous-menu */

// #page-header ul.menu.nav li ul.dropdown-menu li ul {
// 	position: absolute;
// 	/*display: block;*/
// 	background: #ccc;
// 	color: #fff;
// 	left: 100%;
// 	top: 0;
// 	padding: 5px 0;
// }

// #page-header ul.menu.nav li ul.dropdown-menu li ul:before,
// ul.menu.nav li ul.dropdown-menu li:after,
// ul.menu.nav li ul.dropdown-menu li ul:before {
// 	content: none;
// }

// ul.menu.nav li ul.dropdown-menu {
// 	border-radius: 0;
// }

// ul.menu.nav li ul.dropdown-menu li ul {
// 	top: 0;
// 	left: 100%;
// }

// ul.menu.nav li ul.dropdown-menu li a .caret {

// 	border: none !important;
// }

// #page-header ul.menu.nav li ul.dropdown-menu li a span.caret {
// 	display: none;
// 	position: absolute;
// 	margin-left: 0;
// 	border-top: 4px solid transparent;
// 	border-bottom: 4px solid transparent;
// 	position: absolute;
// 	border-left: 4px solid;
// 	right: 10px;
// 	top: 50%;
// 	margin-top: -4px;
// }

// @media(min-width: 1199px) {
// 	#page-header .menu.nav>li.last>a {
// 		padding-left: 28px;
// 		padding-right: 29px;
// 	}
// }

// @media (min-width: 768px) and (max-width: 990px) {
// 	#navbar,
// 	#navbar .navbar-header {
// 		min-height: 115px;
// 	}
// }


/*header mobile*/
@media (max-width: 767px) {
	#navbar a.logo {
		margin-top: -10px;
		width: 80%;
		float: left;
	}
	#navbar a.logo img {
		max-width: 160px;
		margin-top: 0;
		 margin-left: auto;
	  margin-right: auto;
	  display: block;
	}
	#page-header h2 {
		display: none;
	}

	#navbar {
		background: none;
		min-height: 50px;
		padding: 15px 0;
	}
}

/*nav sm*/
@media (min-width: 768px) and (max-width: 990px) {
	#page-header .menu.nav li a {
		padding-left: 8px;
		padding-right: 8px;
	}

	#page-header .menu.nav {
		margin-left: 0;
	}

	ul.menu.nav>li>a,
	#page-header h2 {
		font-size: 11px;
	}

}

/*------------------------------------*/
/*MENU MOBILE*/
/*------------------------------------*/

/*bouton burger*/
button.navbar-toggle {
	float: left;
	margin-right: 0;
	box-shadow: none;
}

.navbar-default .navbar-toggle,
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	border: none;
	background: none;
	padding: 15px 0;
	width: 10%;
}

.navbar-default .navbar-toggle .icon-bar {
	background: #353e49;
	width: 20px;
  	height: 3px;
}

/*bouton recherche */
.navbar-header .loupe-recherche {
	float: right;
	width: 10%;
}

.navbar-header .loupe-recherche img {
	width: 30px;
	margin-top: 15px;
	float: right;
}


/*menu slider gauche*/
#canvas-right {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  z-index: 99999;
  color: #FFF;
  background-color: #d0cccb;
  font-size: 1em;
  top: 0;
  bottom: 0;
  left: -90%;
  width: 90%;
  transition: left 0.3s ease-in-out 0s;
  -webkit-transition: left 0.3s ease-in-out 0s;
  -moz-transition: left 0.3s ease-in-out 0s;
  -o-transition: left 0.3s ease-in-out 0s;
}

#canvas-right .block-mobile {
	float: left;
	width: 100%;
}

#canvas-right .btn {
	color: #FCF7F3;
	border-color: #FCF7F3;
}

.canvas-mask {
	display: none;
	z-index:0;
}
body.canvas-expanded {
	margin-left: 0em;
	transition: left 0.4s ease-in-out 0s;
	-webkit-transition: left 0.4s ease-in-out 0s;
	-moz-transition: left 0.4s ease-in-out 0s;
	-o-transition: left 0.4s ease-in-out 0s;
	overflow: hidden;
}
body.canvas-expanded #canvas-right {
	left: 0;
}
body.canvas-expanded .canvas-mask {
	z-index:9999;
	display:block;
	position: absolute;
	top:0;
	right:0;
	background-color: #262626;
	height: 100%;
	 width: 100%;
	 opacity:0.5;
}

/**/
#canvas-right .reseau,
#canvas-right .domaine {
	padding-top: 20px;
	padding-bottom: 20px;
}

#canvas-right .reseau {
	background: #c3375e;
}

#canvas-right .domaine {
	background: #646371;
}

#canvas-right ul.nav {
	padding-right: 50px;
	background: url(../img/mobile-reseau.png) right 5px no-repeat;
	float: right;
	margin: 0;
}

#canvas-right .vous-etes ul.nav {
	background: url(../img/mobile-vous-etes.png) right 5px no-repeat;
	min-height: 239px;
	padding-top: 40px;
}

#canvas-right .domaine ul.nav {
	background: url(../img/mobile-domaine.png) right 5px no-repeat;
}

#canvas-right a,
#canvas-right ul,
#canvas-right p {
	text-align: right;
	float: right;
}

#canvas-right .domaine a,
#canvas-right .reseau a {
	color: #fff;
}

#canvas-right ul.nav.menu li {
	clear: both;
}

#canvas-right .navbar-nav>li a {
	padding: 7px 15px;
}

#canvas-right ul.menu.nav li:after {
	content: none;
}

#canvas-right button.close {
	padding: 10px 10px 10px 20px;
	font-size: 30px;
	box-shadow: none;
	color: #fff;
	opacity: 1;
}

#canvas-right .dropdown-menu>li>a {
	white-space: normal;
}

#canvas-right ul.sub-menu {
	padding-right: 20px;
	clear: both;
}

@media(max-width:767px) {
	.nav li.open .dropdown-menu:before {
		content: none;
	}
}


/*------------------------------------*/
/*SLIDER HEADER*/
/*------------------------------------*/
header .col-sm-10 {
	width: 77%;
	float: right;
}
/*#slider1_container {
	position: relative;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 120px;
	overflow: hidden;
	margin-top: 15px;
	float: right;
	margin-right: -15px;
}

#slider1_container .slides {
	cursor: pointer;
	position: absolute;
	left: 40px;
	top: 0px;
	width: 100%;
	height: 120px;
	overflow: hidden;
}

#slider1_container .slides a:hover {
	opacity: 0.8;
}*/

/*fleches*/
/*.jssora03l,
.jssora03r,
.jssora03ldn,
.jssora03rdn {
	position: absolute;
	cursor: pointer;
	display: block;
    overflow:hidden;
}

.jssora03l {
	background:url(../img/arrow-left.png) no-repeat;
	width: 13px;
	height: 26px;
	top: 50px;
	left: 0px;
}

.jssora03r {
	background:url(../img/arrow-right.png) no-repeat;
	width: 13px;
	height: 26px;
	top: 50px;
	left: 20px;
}

.jssora03l:hover,
.jssora03r:hover {
	opacity: 0.7;
}*/



/*------------------------------------*/
/*HOMEPAGE*/
/*------------------------------------*/
body.front #bg-front {
	background: none !important;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
	z-index: 0;
	position: relative;
}
/*structure*/
@media (min-width: 768px) {

	body.front .col-sm-6 {
		width: 47%;
	}

	body.front .col-sm-2 {
		width: 19%;
	}
}

.front.domain-vivarais .col-sm-6 h1 {
	font-size: 70px;
}

.front .col-sm-6 h2 {
	text-align: right;
	font-size: 18px;
	color: #787887;
	opacity: 0.8;
	font-family:'UniversLTW01-57Condense_723821', arial, sans-serif;
	font-weight: bold;
	float: left;width: 100%;
}

.front .col-sm-6 h2.title-greta {
	font-size: 55px;
	text-transform: uppercase;
	margin-bottom: 0;
	font-weight: 300;
	margin-top: 60px;
}

.front .col-sm-6 h1.title-greta {
	margin-top: 0;
	font-weight: 300;
	margin-bottom: 30px;
}

.front ul.liste-greta {
	background: rgba(224,224,217,0.57);
	padding: 20px;
	float: right;
	margin: 10px 0 20px;
}

.front ul.liste-greta li {
	list-style: none;
	font-size: 14px;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
}

.front ul.liste-greta li a {
	color: #5c5c67;
	font-family: 'Arial W01 Extra Bold', arial, sans-serif;
	font-weight: inherit;
}

.front ul.liste-greta li a:hover,
.front ul.liste-greta li a:focus,
.front ul.liste-greta li a:active {
	color: #da2a5c;
}


@media (max-width: 767px) {
	.front .col-sm-6 h1 {
		font-size: 65px;
		margin-top: 10px;
		text-align: center;
	}

	.front .col-sm-6 h2 {
		text-align: center;
	}

	.front .col-sm-6 h2.title-greta {
		margin-top: 10px;
	}
}

/*bloc quizz anglais / prendre contact*/
.quizz-anglais,
.prendre-contact {
	float: right;
}

.quizz-anglais {
	clear: right;
	background: #63636d;
	padding: 5px;
	text-align: center;
	color: #fff;
}

.quizz-anglais h5 {
	font-size: 18px;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	margin-bottom: 0;
	margin-top: 0;
}

.quizz-anglais p {
	margin-bottom: 0;
	line-height: 1;
}

.quizz-anglais a {
	color: #fff;
	font-size: 13px;
}

.prendre-contact {
	background: #da2a5c;
	padding: 10px;
	margin-right: 15px;
}

.prendre-contact a {
	color: #fff;
	text-transform: uppercase;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	font-weight: bold;
	line-height: 1;
	display: block;
	text-align: center;
	font-weight: normal!important;
}

aside .prendre-contact {
	margin-right: 0;
	clear: both;
	width: 100%;
}

aside .prendre-contact p {
	margin-bottom: 0;
}

@media (max-width: 767px) {
	.prendre-contact {
		float: none;
		margin-right: 0;
	}
	.front ul.liste-greta {
		float: none;
	}
}

/*bloc actus*/
.block-nodule-news {
	margin-top: 40px;
	background: rgba(255,255,255,0.6);
	padding: 10px;
	float: left;
}
.block-nodule-news h2.block-title {
	text-transform: uppercase;
	font-family: 'UniversLTW01-57Condense_723821', arial, sans-serif;
	font-weight: 700;
}

.block-nodule-news p {
	margin-bottom: 0;
}

.block-nodule-news a {
	color: #9e9ea7;
	font-weight: bold;
	display: block;
	margin-bottom: 5px;
}

.block-nodule-news a.news-title {
	color: #6e6b6b;
	font-size: 20px;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	font-weight: normal;
}

.block-nodule-news a.btn-default {
	margin-top: 10px;
	margin-bottom: 0;
	color: #51515c;
}

.block-nodule-news a.btn-default:hover {
	color: #ceccc9;
}

/*------------------------------------*/
/*PAGE FORMATION*/
/*------------------------------------*/
h2.title-formation {
	float: left;
	width: 80%;
}

.cpf {
	float: right;
	margin-top: -20px;
}

.btn-select {
	position: relative;
	width: 110px;
}
a#select-formation {
	background: #fff;
	padding: 5px 10px 4px 10px;
	margin-bottom: 40px;
	display: inline-block;
	z-index: 10;
	line-height: 1.1;
	font-size: 11px;
}

.btn-select:after {
	content: "";
	width: 69px;
	height: 61px;
	position: absolute;
	right: -50px;
	top:3px;
	background: url(../img/ico-star.png) center center no-repeat;
	z-index: -1;
}

a#download-pdf {
	margin-top: 10px;
	display: inline-block;
	background: #fff;
}

a#select-formation:hover,
a#select-formation:focus,
a#select-formation:active,
a#download-pdf:hover,
a#download-pdf:focus,
a#download-pdf:active {
	background: #353e49;
	color: #ceccc9;
}

/*information pratique */
#contact .col-sm-6:nth-child(2n+4) {
	clear: left;
}

#contact .col-sm-6 {
	margin-bottom:15px;
}

/*autres formations*/
#autres-formations a,
#cpf-formations a {
	color: #4c4b53;
}

#autres-formations .col-sm-6:nth-child(2n+4) {
	clear: left;
}

#autres-formations a:hover,
#cpf-formations a:hover,
#autres-formations a:focus,
#cpf-formations a:focus,
#autres-formations a:active,
#cpf-formations a:active {
	opacity: 0.8;
	text-decoration: underline;
}
/*------------------------------------*/
/*SIDEBAR DROITE */
/*------------------------------------*/
/*bloc libre de base */
.block-block {
	padding: 15px;
	margin-top: 15px;
	float: left;
	width: 100%;
}

.block-block h2,
.block-block h4 {
	margin-top: 0;
}

.block-block a {
	font-weight: bold;
}

/*couleurs*/
.block-sidebar {
	padding: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.color-sidebar-gris {
	background: rgba(126,131,144,1);
	color: #fff;
}

.color-sidebar-transparent {

}
.color-sidebar-acad {
	background: rgba(218,42,92,1);
	color: #fff;
}
.color-sidebar-arve {
	background: rgba(8,147,180,1);
	color: #fff;
}
.color-sidebar-grenoble {
	background: rgba(233,88,26,1);
	color: #fff;
}
.color-sidebar-lac, .color-sidebar-savoiehautesavoie {
	background: rgba(39,127,199,1);
	color: #fff;
}
.color-sidebar-savoie {
	background: rgba(246,175,24,1);
	color: #595e68;
}
.color-sidebar-nord {
	background: rgba(196,5,77,1);
	color: #fff;
}
.color-sidebar-viva5 {
	background: rgba(165,150,201,1);
	color: #fff;
}
.color-sidebar-vivarais {
	background: rgba(105,172,86,1);
	color: #fff;
}
.color-sidebar-gris h4,
.color-sidebar-blanc h4,
.color-sidebar-acad h4,
.color-sidebar-arve h4,
.color-sidebar-grenoble h4,
.color-sidebar-lac h4,
.color-sidebar-savoiehautesavoie h4,
.color-sidebar-nord h4,
.color-sidebar-viva5 h4,
.color-sidebar-vivarais h4,
.color-sidebar-gris h3,
.color-sidebar-blanc h3,
.color-sidebar-acad h3,
.color-sidebar-arve h3,
.color-sidebar-grenoble h3,
.color-sidebar-lac h3,
.color-sidebar-savoiehautesavoie h3,
.color-sidebar-nord h3,
.color-sidebar-viva5 h3,
.color-sidebar-vivarais h3,
.color-sidebar-gris a,
.color-sidebar-blanc a,
.color-sidebar-acad a,
.color-sidebar-arve a,
.color-sidebar-grenoble a,
.color-sidebar-lac a,
.color-sidebar-savoiehautesavoie a,
.color-sidebar-nord a,
.color-sidebar-viva5 a,
.color-sidebar-vivarais a {
	color: #fff;
}

/*dates prevues*/
.bloc-date-formation {
	background: #cdc9bd;
	margin-top: 15px;
	margin-bottom: 15px;
	padding:15px 0 0 0;
	clear: both;
	float: left;
	width: 100%;
}

.bloc-date-formation span,
.bloc-date-formation a,
.bloc-libre span,
.bloc-libre a {
	color: #fff;
	display: block;
	margin: 10px 0;
}

.bloc-date-formation span,
.bloc-libre span {
	margin: 0;
}

.bloc-date-formation h4 {
	font-size: 15px;
	margin-bottom: 0;
}

.bloc-date-formation h4,
.bloc-date-formation h5,
.bloc-date-formation ul,
.bloc-date-formation p {
	padding: 0 15px;
}

.bloc-date-formation h5 {
	border-bottom: 1px solid #fff;
	padding-bottom: 5px;
	font-weight: bold;
	font-size: 13px;
	margin-top: 15px;
}

.bloc-date-formation ul li {
	list-style: none;
	font-weight: bold;
	font-size: 13px;
}

/*bloc contact*/
.bloc-contact {
	background: #c3325c;
	padding: 0;
}

.bloc-contact a {
	color: #fff;
	padding: 15px 15px;
	text-align: center;
	display: block;
}

/*bloc photo */
.bloc-photo {
	background: #4c5d72;
	color: #fff;
	margin-top: 45px;
	padding: 40px 15px 15px;
	position: relative;
	box-shadow: -2px 0 2px rgba(0,0,0,0.25);
}

.bloc-photo h2 {
	background: url(../img/ico-photo.png) center center no-repeat;
	padding-top: 30px;
	height: 61px;
	width: 81px;
	color: #353e49;
	margin: 0;
	text-align: center;
	position: absolute;
	top: -30px;
}

.bloc-photo img {
	padding-top: 10px;
	padding-bottom: 10px;
}

.bloc-photo a {
	color: #ceccc9;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	display: block;
}

/*bloc video*/
.bloc-video {
	background: #e8e6e6;
	box-shadow: -2px 0 2px rgba(0,0,0,0.25);
	position: relative;
	margin-top: 60px;
	padding: 15px 15px 15px;
}

.bloc-video h2 {
	background: url(../img/ico-video.png) center center no-repeat;
	padding-top: 40px;
	height: 74px;
	width: 85px;
	color: #fff;
	margin: 0;
	text-align: center;
	position: absolute;
	top: -45px;
    font-size: 13px;
    font-family: 'Arial W01 Extra Bold', arial, sans-serif;
    text-transform: uppercase;
}

.bloc-video h5 {
	color: #5c5c67;
	font-weight: bold;
	margin-bottom: 0px;
	text-align: center;
	padding-top: 15px;
}

.bloc-video .img-video {
	position: relative;
	display: block;
	padding-top: 5px;
	padding-bottom: 10px;
}

.bloc-video .img-video:before {
	content: "";
	background: url(../img/ico-player-video.png);
	width: 73px;
	height: 50px;
	position: absolute;
	left: 50%;
	margin-left: -36px;
	top: 50%;
	margin-top: -25px;
}

.bloc-video a{
	color: #5c5c67;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	display: block;
}

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;
}
div:not(.no-video)>div>section> .embed-responsive .embed-responsive-item,
div:not(.no-video)>div>section> .embed-responsive iframe,
div:not(.no-video)>div>section> .embed-responsive embed,
div:not(.no-video)>div>section> .embed-responsive object,
div:not(.no-video)>div>section> .embed-responsive video,
aside>div>section> .embed-responsive .embed-responsive-item,
aside>div>section> .embed-responsive iframe,
aside>div>section> .embed-responsive embed,
aside>div>section> .embed-responsive object,
aside>div>section> .embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}
.embed-responsive-16by9 {
	padding-bottom: 56.216%;
}
.embed-responsive-4by3 {
	padding-bottom: 75%;
}

.no-video .embed-responsive-16by9 {
	padding-bottom: 0;
	overflow: auto;
	height: auto;
}

.embed-responsive-item {
	position: relative;
}

.no-video .block-block {

}

/*block catalogue*/
.catalogue {
	background: url(../img/bg-mondossier.png) right top no-repeat;
	padding: 0 22px 0 0;
 	margin-right: -15px;
}

.catalogue .block-catalogue {
	background: #d3cdc7;
	padding: 15px;
}

.catalogue h2 {
	font-size: 13px;
	color: #fff;
	text-transform: uppercase;
	font-family: 'Arial W01 Extra Bold', arial, sans-serif;
	margin-top: 0;
	word-break: break-word;
}

.catalogue h2 span {
	color: #4c4b53;
	text-transform: lowercase;
	font-family: 'Arial W01 Extra Bold', arial, sans-serif;
}

.catalogue h3 {
	background: none;
	padding: 0;
	color: #4c4b53;
	text-transform: uppercase;
	font-size: 13px;
	margin-top: 0;
}

.catalogue ul li {
	list-style-type:none;
	line-height: 1.1;
	position: relative;
	margin-bottom: 10px;
}

.catalogue ul li a {
	font-weight: normal;
}

.catalogue a.badge {
	position: absolute;
	top: 0;
	right: -20px;
	font-size: 8px;
	padding: 3px 4px;
	margin-top: -2px;
}

.catalogue a.badge:hover {
	opacity: 0.5;
}

.catalogue a.btn-default:hover,
.catalogue a.btn-default:focus,
.catalogue a.btn-default:active {
	background: #353e49;
	color: #ceccc9;
}

/*sidebar sm tablette*/
@media (min-width: 768px) and (max-width: 990px) {
	aside.col-sm-2 {
		padding-right: 0;
	}

	.catalogue {
		padding: 0 15px 0 0;
	}

	.catalogue .block-catalogue {
		padding: 10px;
	}
}

/*sidebar mobile*/
@media(max-width: 767px) {
	aside {
		margin-top: 20px;
	}
}

/*------------------------------------*/
/*SIDEBAR GAUCHE */
/*------------------------------------*/

/*block info */
aside.col-sm-4 .block-block {
	/*background: #c3325c;*/
	color: #fff;
	width: 100%;
	float: right;
	margin-top: 40px;
	margin-bottom: 15px;
	clear: both;
}

aside.col-sm-4 .block-block h4 {
	color: #fff;
}

@media (min-width: 768px) and (max-width: 990px) {
	aside.col-sm-4 .block-block {
		width: 75%;
	}
}

@media (max-width: 767px) {
	aside.col-sm-4 .block-block {
		width: 100%;
		float: left;
		display: none;
	}
}

/*suivez nous sur facebook*/
#block-nodule-contact-facebook {
	font-size: 10px;
	text-transform: uppercase;
	color: #353e49;
	font-weight: bold;
	margin-top: 15px;
	position: relative;
	padding-right: 30px;
	text-align: center;
}

#block-nodule-contact-facebook>a {
	background: url(../img/ico-fb.jpg) center center no-repeat;
	height: 21px;
	width: 21px;
	text-indent: -9999px;
	margin: 5px;
    display: inline-block;
}

#block-nodule-contact-facebook span.title {
	display: block;
}

#block-nodule-contact-facebook>a.g {
	background: url(../img/ico-g.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.tw {
	background: url(../img/ico-tw.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.vdo {
	background: url(../img/ico-vdo.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.vmo {
	background: url(../img/ico-vmo.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.yte {
	background: url(../img/ico-yte.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.sct {
	background: url(../img/ico-sct.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.tbr {
	background: url(../img/ico-tbr.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.ska {
	background: url(../img/ico-ska.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.dmn {
	background: url(../img/ico-dmn.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.ins {
	background: url(../img/ico-ins.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.pin {
	background: url(../img/ico-pin.jpg) center center no-repeat;
}

#block-nodule-contact-facebook>a.ld {
	background: url(../img/ico-lk.jpg) center center no-repeat;
}

/*------------------------------------*/
/*ASIDE RECHERCHE*/
/*------------------------------------*/
/*aside {
	float: left;
}*/

aside.col-sm-4 .recherche {
	background: none!important;
	padding: 0;
	width: 100%;
	position: relative;
	float: none;
	margin-top: 20px;
}

aside .well {
	background: none;
	border: none;
	border-radius: 0;
	position: relative;
	padding: 0;
	box-shadow: none;
}

aside.col-sm-4 .bloc-recherche:before {
	content: "";
	min-height: 540px;
	background: url(../img/bg-recherche.png) center top no-repeat;
	position: absolute;
	top: -60px;
	left: -70px;
	background-size: 100%;
	z-index: -1;
	width: 500px;
}

aside .bloc-recherche {
	padding-top: 50px;
}

.recherche {
	text-align: center;
	padding-top: 60px;
}

.recherche:before {
	content: "";
	position: absolute;
	top: -10px;
	width: 83px;
	height: 58px;
	left: 50%;
	margin-left: -42px;
	background: url(../img/arrow-rechercher.png) center center no-repeat;
}

.recherche h2 {
	font-family: arial, sans-serif;
	margin: 0;
	color: #000;
}

.recherche a {
	color: #adabab;
	font-size: 36px;
	border-bottom: 1px dashed #c6c5c6;
	display: inline-block;
}

.recherche a#sh_specialite {
	font-size: 30px;
}

.recherche p {
	color: #5d5d68;
	font-size: 24px;
	margin: 0;
	line-height: 1;
}

.recherche form button {
	margin-top: 15px;
	margin-right: auto;
	margin-left: auto;
	background: #ceccc9
}

.recherche form {
	background: none;
}

.recherche form ul {
	display: none;
}

/*recherche overlay*/
.form-item-departements .radio input[type=radio],
.form-item-departements .radio-inline input[type=radio],
.form-item-departements .checkbox input[type=checkbox],
.form-item-departements .checkbox-inline input[type=checkbox] {
	margin-top: 15px;
}

.form-item-departements .checkbox {
	margin-top: 15px;
	margin-bottom: 15px;
}

.recherche #search_form {
	color: #7e8390;
}

.recherche #search_form h2 {
	color: #7e8390;
	font-family: 'Arial W01 Extra Bold', arial, sans-serif;
	font-size: 26px;
}

.recherche #search_form .ville {
	margin-bottom: 20px;
}

@media (min-width: 991px) and (max-width: 1199px) {
	aside.col-sm-4 .bloc-recherche:before {
		left: -110px;
	}
}

@media (min-width: 768px) and (max-width: 990px) {
	aside.col-sm-4 .bloc-recherche:before {
		left: -150px;
	}
}


@media (max-width: 767px) {
	body.front aside.col-sm-4 {
		padding: 0;
	}

	/*aside.col-sm-4 .recherche {
		overflow: hidden;
		display: none;
		margin-top: 20px;
	}

	body.front aside.col-sm-4 .recherche {
		display: block;
	}*/

	aside.col-sm-4 .bloc-recherche:before {
		top: 0;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		min-height: inherit;
	}
}

/*------------------------------------*/
/*LISTE FORMATION*/
/*------------------------------------*/
.term-listing-heading form {
	background: none;
	padding: 0;
}

.term-listing-heading form input.ctools-auto-submit-click {
	display: none;
}

.liste-formation #header_checkbox {
	margin-top: 20px;

}

.liste-formation .article-formation,.liste-formation #header_checkbox {
	margin-bottom: 15px;
	padding: 5px;
        background: rgba(255,255,255,0.6);
        box-shadow: 0 0 8px rgba(0,0,0,0.2);
        border:1px transparent solid;
        /*padding-left: 40px;*/
}
.liste-formation .article-formation:hover {

	border:1px #d53e69 solid;
}
.liste-formation .article-formation a:hover {

	/*padding: 5px;*/
	text-decoration: underline;
       /* padding-left: 40px;*/
}

.liste-formation h3,
.liste-panier h3 {
	text-transform: uppercase;
}

.article-formation p {
	color: #4c4b53;
	margin-bottom: 0;
	font-weight: 300;
}

.article-formation i {
	font-weight: 300;
	word-wrap: break-word;
}

/*pas de resultat*/
p.no-result {
	font-size: 14px;
	margin-top: 20px;
}

p.no-result a {
	color: #c3325c;
	text-decoration: underline;
}

p.result {
	font-size: 14px;
	font-weight: bold;
	float: left;
	margin: 20px 0;
}

@media (max-width: 767px) {
	.term-listing-heading form select {
		margin-bottom: 15px;
	}

	p.result,
	.nav-tabs.list-map {
		float: none!important;
	}
}

/*------------------------------------*/
/*PAGE RESULTAT RECHERCHE*/
/*------------------------------------*/

/*tabbar */
.loading {
	display: block;
	width: 100%;
	float: left;
}
.tab-pane {
	margin-top: 15px;
}
.nav-tabs.list-map {
	border: none;
	float: right;
	margin-bottom: 15px;
}

.nav-tabs.list-map>li>a {
	border-radius: 0;
	border: none;
	padding: 5px 8px;
	margin-left: 15px;
}

.nav-tabs.list-map>li>a:hover,
.nav-tabs.list-map>li>a:focus,
.nav-tabs.list-map>li>a:active {
	border: none;
	background: #fff;
	color: #333;
}

.tab-content #map {
	margin-top: 15px;
}

.nav-tabs.list-map>li.active>a,
.nav-tabs.list-map>li.active>a:hover,
.nav-tabs.list-map>li.active>a:focus {
	background: #353e49;
  color: #ceccc9;
  box-shadow: -4px 0 5px -1px rgba(0, 0, 0, 0.9) inset;
}

#formations_map {
	float: left;
}

/*affichage carte*/
#formations_map_wrapper a {
	color: #595e68;
	margin-bottom: 10px;
}

#formations_map_wrapper a:hover {
	color: #c3325c;
}
#formations_map_wrapper h2 {
	margin-top: 10px;
	font-size: 30px;
}

#formations_map_wrapper ul {
	margin-bottom: 0;
}

#formations_map_wrapper ul li {
	margin: 5px 0;
}

/*------------------------------------*/
/*PAGE ACTUALITES*/
/*------------------------------------*/
/*liste*/
body.page-actualites h1.page-header {
	margin-left: 50%;
	padding-left: 15px;
}
article.node-news h3 a {
	color: #595e68;
	font-size: 20px;
	font-family:'Arial W01 Extra Bold', arial, sans-serif;
	text-transform: initial;
}

article.node-news img {
	max-width: 100%;
	height: auto;
}

article.node-news p.cat_date {
	position: relative;
}

article.node-news .categorie {
	color: #fff;
	background: #d53e69;
	padding: 5px 8px 2px;
	clear: both;
	display: inline-block;
}

article.node-news .categorie:before {
	  content: "";
  position: absolute;
  left: 0px;
  right: 0;
  top: 0px;
  height: 1px;
  background: #fff;
}

article.node-news .date {
	display: block;
	margin-top: 10px;
	font-weight: bold;
}

article.node-news .cat_date {
	margin-bottom: 5px;
}

article.node-news.node-teaser .cat_date {
	margin-top: 0px;
}

@media (max-width: 990px) {
	body.page-actualites h1.page-header {
		margin-left: 0;
		padding-left: 0;
	}
}

/*couleurs / greta*/

/*details*/
article.node-news .cat_date {
	margin-top: 10px;
}

/*------------------------------------*/
/*PAGINATION*/
/*------------------------------------*/
.pagination {
	margin-top: 40px;
}
.pagination,
.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li:first-child>a,
.pagination>li:first-child>span  {
	border-radius: 0;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	background: #353e49;
	border-color: #fff;
}

.pagination>li>a,
.pagination>li>span,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus{
	color: #353e49;
	border-color: #fff;
	background: #EBE9E9;
}

/*------------------------------------*/
/*PAGE PANIER*/
/*------------------------------------*/
.list-group-item {
	background: transparent;
	padding: 7px 5px;
	border-radius: 0;
	border-left: none;
	border-right: none;
	border-top: 1px solid #353e49;
	border-bottom: 1px solid #353e49;
}

.list-group-item:first-child,
.list-group-item:last-child {
	border-radius: 0;
}

.list-group-item a {
	color: #4c4b53;
}

.list-group-item .badge {
	background: transparent;
	border: 2px solid #979697;
	padding: 3px 4px;
 	 border-radius: 100%;
 	 margin-top: -3px;
 	 color: #979697;
}

.list-group-item:hover {
	background: #fff;
}

.list-group-item:hover a {
	color: #d53e69
}

.list-group-item:hover a.badge {
	color: #979697
}

.list-group-item a.badge:hover {
	border-color: #4c4c53;
	color:#4c4c53;
}


/*------------------------------------*/
/*FOOTER*/
/*------------------------------------*/



#block-menu-menu-footer {
	min-height: 163px;
}

footer .region-footer ul.menu.nav {
	position: absolute;
	bottom: 10px;
	right: 0;
}

footer ul.menu.nav li {
	float: left;
}

footer ul.menu.nav li:after {
	border-color: #fff;
}

footer ul.menu.nav li:last-child:after {
	content: none;
}

footer .region-footer ul.menu.nav li a {
	color: #fff;
	font-size: 10px;
}

@media(max-width: 990px) {
	footer .region-footer ul.menu.nav {
		bottom: 50px;
	}

	footer {
		padding-top: 100px;
	}
}

@media (max-width: 767px) {

	footer ul.menu.nav li {
		float: right;
	}

	footer .region-footer ul.menu.nav li {
		padding: 5px 0px;
	}
}


/*------------------------------------*/
/*WYSIWYG*/
/*------------------------------------*/
div.btn-button a {
	color: #51515c;
	text-transform: uppercase;
	font-size: 11px;
	background: #ceccc9;
	padding: 5px 8px;
	box-shadow: -4px 0 8px -1px rgba(0, 0, 0, 0.3) inset;
	margin-bottom: 10px;
	display: inline-block;
}
div.btn-button a:hover {
	background: #353e49;
	color: #ceccc9;
}

/*----------*/
/*gestion des couleurs*/
/*----------/*


/*COMMUN*/
/*texte */
.reso-texte {
	color: rgb(76,75,83);
}

.reso-texte-lien-1 {
	color: rgb(148,40,74);
}

.reso-texte-lien-2 {
	color: rgb(231,77,81);
}

.reso-texte-gris-3 {
	color: rgb(126,131,144);
}

.reso-texte-gris-4 {
	color: rgb(92,92,103);
}

.reso-texte-blanc {
	color: rgb(255,255,255);
}

.reso-texte-beige-2 {
	color: rgb(207,204,202);
}



.reso-link-lien-1 {
	color: rgb(148,40,74);
}

.reso-link-lien-2 {
	color: rgb(231,77,81);
}


/*background*/

.reso-bg-lien-1 {
	background: rgb(148,40,74);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-gris-1 {
	background: rgb(212,212,216);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-gris-3 {
	background: rgb(126,131,144);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-gris-4 {
	background: rgb(92,92,103);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-blanc {
	background: rgb(255,255,255);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-beige-1 {
	background: rgb(232,231,229);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-beige-2 {
	background: rgb(207,204,202);
	padding: 15px;
	margin-bottom: 15px;
}

/*SPECIFIQUE*/

/*texte*/
.acad-texte-rose-2 {
	color: rgb(180,37,85);
}

/*background*/
.acad-bg-rose-1-logo {
	background: rgb(218,42,92);
	padding: 15px;
	margin-bottom: 15px;
	color: #fff;
}

.acad-bg-rose-2 {
	background: rgb(180,37,85);
	padding: 15px;
	margin-bottom: 15px;
	color: #fff;
}

/*---------------------------------*/
/* GESTIONS COULEURS*/
/*---------------------------------*/
/*body .encart-couleur {
	background: #d53e69;
	padding: 10px;
	color: #fff;
}

.encart-gris {
	padding: 15px;
	background: #7e8390;
	color: #fff;
	margin: 15px 0;
}*/

.modal-dialog {
  padding-top: 10%;
}

.boutonVideo .img-video:before{
	content: "";
	background: url(../img/ico-player-video.png);
	width: 73px;
	height: 50px;
	position: absolute;
	left: 50%;
	margin-left: -36px;
	top: 50%;
	margin-top: -10px;
}

.boutonVideo h5 {
	color: #5c5c67;
	font-weight: bold;
	margin-bottom: 5px;
	text-align: center;
}

.fondCarousel{
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	left: 0;
	height: 20%;
	bottom: 9px;
}

.titreCarousel{
	margin-top: 3px;
	color: white;
	font: 20px bold;
}

.legendeCarousel{
	margin-top: 3px;
	color: white;
	font: 15px bold;
}
.liste-formation .article-formation.tamponcpf
{
    position: relative;
    padding-right: 35px;
}
.tamponcpf:after
{
    content: "";
    width: 23px;
    height: 23px;
    position: absolute;
    right: 8px;
    margin-top: -13px;
    top: 50%;
    background: url(/sites/all/themes/greta2015/img/tampon-CPF-liste.png) center center no-repeat;

}
.checkbox_catalogue, #header_checkbox input.all{
    margin-right: 10px;
    margin-left: 0px;
    float: left;margin-top: 5px;
}

 #header_checkbox label{margin-top: 3px;}

/*message cookie */
@media (max-width:990px) {
	#sliding-popup.sliding-popup-bottom {
		padding-bottom: 50px;
	}
}


.block-nodule-news a.btn-actu-sidebar {
	background: #da2a5c;
	padding: 10px;
        color:white;
        text-align: center;
        text-transform: uppercase;
}
/***
* LOADER
***/
#catalog-form{
  position: relative;
}
.loader-circle {
  margin: 150px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid #cfccca;
  border-right: 0.5em solid #cfccca;
  border-bottom: 0.5em solid #cfccca;
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader-circle,
.loader-circle:after{
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loader-full{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    background: #ffffff;
    opacity: 0;
    z-index: 1200;
}

