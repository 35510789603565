@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

.clearfix {
	@include clearfix;
}

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

@mixin input_mobile() { 
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
}

@mixin center($width: null, $height: null) {
	position: absolute;
	top: 50%;
	left: 50%;

	@if not $width and not $height {
		transform: translate(-50%, -50%);
	} @else if $width and $height {
		width: $width;
		height: $height;
		margin: -($width / 2) #{0 0} -($height / 2);
	} @else if not $height {
		width: $width;
		margin-left: -($width / 2);
		transform: translateY(-50%);
	} @else {
		height: $height;
		margin-top: -($height / 2);
		transform: translateX(-50%);
	}
}