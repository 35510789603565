@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/montserratlight.eot');
	src: url('../fonts/montserratlight.eot') format('embedded-opentype'),
		 url('../fonts/montserratlight.woff2') format('woff2'),
		 url('../fonts/montserratlight.woff') format('woff'),
		 url('../fonts/montserratlight.ttf') format('truetype'),
		 url('../fonts/montserratlight.svg#Montserrat') format('svg');
	font-style: normal;
	font-weight: 100;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratRegular.eot');
	src: url('../fonts/MontserratRegular.eot') format('embedded-opentype'),
		 url('../fonts/MontserratRegular.woff2') format('woff2'),
		 url('../fonts/MontserratRegular.woff') format('woff'),
		 url('../fonts/MontserratRegular.ttf') format('truetype'),
		 url('../fonts/MontserratRegular.svg#Montserrat') format('svg');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratSemiBold.eot');
	src: url('../fonts/MontserratSemiBold.eot') format('embedded-opentype'),
		 url('../fonts/MontserratSemiBold.woff2') format('woff2'),
		 url('../fonts/MontserratSemiBold.woff') format('woff'),
		 url('../fonts/MontserratSemiBold.ttf') format('truetype'),
		 url('../fonts/MontserratSemiBold.svg#Montserrat') format('svg');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'MontserratBold';
	src: url('../fonts/MontserratBold.eot');
	src: url('../fonts/MontserratBold.eot') format('embedded-opentype'),
		 url('../fonts/MontserratBold.woff2') format('woff2'),
		 url('../fonts/MontserratBold.woff') format('woff'),
		 url('../fonts/MontserratBold.ttf') format('truetype'),
		 url('../fonts/MontserratBold.svg#MontserratBold') format('svg');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../fonts/MontserratExtraBold.eot');
	src: url('../fonts/MontserratExtraBold.eot') format('embedded-opentype'),
		 url('../fonts/MontserratExtraBold.woff2') format('woff2'),
		 url('../fonts/MontserratExtraBold.woff') format('woff'),
		 url('../fonts/MontserratExtraBold.ttf') format('truetype'),
		 url('../fonts/MontserratExtraBold.svg#Montserrat') format('svg');
	font-style: normal;
	font-weight: 900;
}
